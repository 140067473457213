import React from 'react';
import people from '../../assets/people.png';
import ai from '../../assets/ai.png';
import qrCode from '../../animation_2.json';
import './header.css';
import Lottie from 'lottie-react';

const Header = () => (
  <div>

  <div className="gpt3__header" id="home">
    <div className="gpt3__header-content">
      <h1 className="gradient__text">Timothy App - creation made fun</h1>
      <p>You want to make your friends laugh? Great, downlaod Timothy and put a picture of your friend on a fun background. For any questions you can easily approach us through email: timothy.app.info@gmail.com</p>
    
    </div>

    <div className="division_one">
      <button type='button'> 
        <a href="https://play.google.com/store/apps/details?id=com.timothy"
            target="_blank"
            rel="noreferrer">
           Google Play
        </a>
      </button>
    </div>
  </div>

  <div className='image'>
  <img src={require('../../assets/image.png')}  />
  <img src={require('../../assets/imageeae.png')}  />
  </div>
  </div>
);

export default Header;
