import React from 'react';
import possibilityImage from '../../assets/possibility.png';
import './possibility.css';
import fork from '../../fork-anim.json';
import Lottie from 'lottie-react';
import Feature from '../../components/feature-two/Feature';
import { Link } from 'react-router-dom';

const featuresData = [
  {
    title: '1.',
    text: 'Timothy app is not free. The cost of the app is 2.99 €.',
  },
  {
    title: '2.',
    text: 'App has a Privacy Polici and Terms & Conditions which you can also approach by clicking on the buttons above.',
  },
  {
    title: '3.',
    text: 'To download the app, click on the Google Play button above and it will lead you to the download page.',
  },
  {
    title: '4.',
    text: 'Timothy app is available only on Google Play and cannot be downloaded from any other platform.',
  },
];

const Possibility = () => (
  <div className="gpt3__possibility" id="possibility">

    <div className="gpt3__possibility-content">
      <h1 className="gradient__text">Make your friends laugh with our Timothy app...</h1>
      <p>Timothy is an app created for fun. It's main goal is to enable you to make funny images and share them with your friends. There are two possibilities of creating a timothy image. First is to choose a background from your device, and the other one is to choose one of the listed backgrounds that you get with the app. To check our Privacy Policy or Terms & Conditions, click on the buttons bellow.</p>
      
      <div className="gpt3__cta-btn-1">
        <Link to='/privacypolicy'><button type="button">Privacy Policy</button></Link>
        <Link to='/terms&conditions'><button type="button">Terms & Conditions</button></Link>
      </div>

      <div className="gpt3__features section__padding" id="features">
        <div className="gpt3__features-container">
          {featuresData.map((item, index) => (
            <Feature title={item.title} text={item.text} key={item.title + index} />
          ))}
        </div>
      </div>
    </div>
  </div>
);

export default Possibility;
