import logo from './logo.svg';

import { Footer, Blog, Possibility, Features, WhatGPT3, Header } from './containers';
import { CTA, Brand, Navbar } from './components';
import Main from './Main';
import { Route, Routes } from 'react-router-dom';
import PrivacyPolicy from './pages/privacy-policy/PrivacyPolicy';
import TermsAndConditions from './pages/terms-and-conditions/TermsAndConditions';

function App() {
  return (
    <div className="App">

      <Routes>

        <Route path='/' element={<Main />} />
        <Route path='/privacypolicy' element={<PrivacyPolicy />} />
        <Route path='/terms&conditions' element={<TermsAndConditions />} />

      </Routes>
    </div>
  );
}

export default App;
